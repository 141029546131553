import type { ComponentType } from "react"
import styled from "styled-components"
// Learn more: https://www.framer.com/docs/guides/overrides/

export function hideScrollbar(Component): ComponentType {
    const ScrollableComponent = styled(Component)`
    /* Hide the scrollbar */
    &::-webkit-scrollbar {
      display: none; /* Chrome */
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  `
    return (props) => <ScrollableComponent {...props} />
}
